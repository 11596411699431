import React from 'react';

import Icon from '@images/rettungsreifen.svg';
import Link from 'next/link';

type Props = {};

function Custom404() {
  return (
    <div className="not-found">
      <h1>404</h1>
      <Icon className="not-found__icon" />
      <p>Diese Seite existiert leider nicht.</p>
      <Link
        href="/"
        passHref
        className="button button--primary not-found__link"
      >
        Zurück zur Startseite
      </Link>
    </div>
  );
}

export default Custom404;
